.PairInputBox {
    position: relative;
    float: left;
    display: block;
    margin: 4px 5px;
    padding: 8px 5px 0 5px;
    width: calc(100% - 10px);
}

.PairInputBox .InputLabel {
    display: block;
    float: left;
    width: 100%;
    font-size: 0.8rem;
    margin: 0;
    padding: 0;
    text-align: left;
}

.PairInputBox .Input,
.PairInputBox .Select,
.PairInputBox .Button {
    float: left;
    display: block;
    background: transparent;
    float: left;
    font-size: 14px;
    margin: 0;
    padding: 4px 12px;
    height: 30px;
}

.PairInputBox .Input {
    border: 0;
    border-bottom: 1px solid var(--gray);
}

.PairInputBox .Input.WithButton {
    padding: 4px 30px 0px 12px;
}

.PairInputBox .Input:hover {
    border-bottom: 1px solid var(--primary);
}

.PairInputBox .Input:focus,
.PairInputBox .Input:active {
    border: 1px solid var(--primary);
}

.PairInputBox.Error .Input {
    border: 1px solid var(--red);
}

.PairInputBox .Button {
    border: 1px solid var(--gray);
}

.PairInputBox .Select {
    border: 1px solid var(--gray);
}