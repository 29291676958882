.DangerButton,
.WarningButton,
.InfoButton,
.ActionButton {
    position: absolute;
    right: 5px;
    bottom: 0px;
    height: 30px;
    max-height: 30px;
    width: 30px;
    padding: 0;
    margin: 0;
    background: transparent;
    font-size: inherit;
    z-index: 5;
}

.DangerButton {
    color: var(--red);
}

.WarningButton {
    color: var(--yellow);
}

.InfoButton {
    color: var(--green);
}

.ActionButton {
    color: var(--primary);
}

.DangerButton i,
.WarningButton i,
.InfoButton i,
.ActionButton i {
    position: absolute;
    bottom: 7px;
    right: 5px;
}