.Backdrop {
	width: 100vw;
	height: 100vh;
	position: fixed;
	z-index: 200;
	bottom: 0;
	right: 0;
	background-color: #00000080;
}

.Backdrop span {
	color: #fff;
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translate(-50%);
}