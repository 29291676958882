.Button {
	float: left;
	position: relative;
	display: block;
	border: none;
	color: white;
	cursor: pointer;
	font: inherit;
	padding: 8px 12px;
	margin: 10px;
	font-size: 14px;
	font-weight: bold;
	transition: any 0.5s;
	border-radius: 2px;
}

.Primary {
	color: #fff;
	background-image: linear-gradient(90deg, var(--primary), var(--primary-dark), var(--primary), var(--primary-dark));
	background-size: 300%;
	background-position: 0;
}

.Info {
	color: #fff;
	background-image: linear-gradient(90deg, #afac0a, #aa9a07, #afac0a, #aa9a07);
	background-size: 300%;
	background-position: 0;
}

.Success {
	color: #fff;
	background-image: linear-gradient(90deg, #67ad06, #4c7a0a, #67ad06, #4c7a0a);
	background-size: 300%;
	background-position: 0;
}

.Danger {
	color: #fff;
	background-image: linear-gradient(90deg, #940505, #530000, #940505, #530000);
	background-size: 300%;
	background-position: 0;
}

.Button:hover,
.Button:focus {
	/* box-shadow: var(--box-shadow); */
	animation: buttonHover linear 0.6s;
}

.Button:active {
	text-shadow: var(--text-shadow);
	box-shadow: var(--inset-shadow);
}

@keyframes buttonHover {
	0% {
		background-position: 0%;
	}

	100% {
		background-position: 100%;
	}
}