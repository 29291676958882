.Column,
.ColumnFull,
.Column80,
.Column75,
.Column70,
.Column60,
.Column50,
.Column40,
.Column33,
.Column30,
.Column25,
.Column20 {
	position: relative;
	float: left;
	display: block;
	width: 100%;
}

@media (min-width:420) {}

@media (min-width:576px) {
	.Column20 {
		width: 50%;
	}
}

@media (min-width:768px) {
	.Column60 {
		width: 60%;
	}

	.Column50 {
		width: 50%;
	}

	.Column40 {
		width: 40%;
	}

	.Column33 {
		width: 50%;
	}

	.Column25 {
		width: 50%;
	}

	.Column20 {
		width: 33%;
	}
}

@media (min-width:992px) {
	.Column80 {
		width: 80%;
	}

	.Column70 {
		width: 50%;
	}

	.Column33 {
		width: 33.33%;
	}

	.Column25 {
		width: 25%;
	}

	.Column20 {
		width: 20%;
	}
}