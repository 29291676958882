.InputBox {
    position: relative;
    float: left;
    display: block;
    margin: 4px 5px;
    padding: 8px 5px 0 5px;
    width: calc(100% - 10px);
}

.InputLabel {
    display: block;
    float: left;
    width: 100%;
    font-size: 0.8rem;
    margin: 0;
    padding: 0;
    text-align: left;
}

.Required {
    color: var(--red);
    font-size: inherit;
}

.InputBox .Error {
    position: absolute;
    right: 0;
    bottom: 35px;
    max-width: 100%;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px 8px;
    font-size: 0.8rem;
    margin-left: -60px;
    transition: opacity 0.3s;
    background-color: var(--red);
    z-index: 3;
}

.InputBox .Error::before {
    content: "";
    position: absolute;
    top: 100%;
    right: 20px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: var(--red) transparent transparent transparent;
    z-index: 3;
}

.InputBox .Input {
    float: left;
    display: block;
    background: transparent;
    width: 100%;
    float: left;
    font-size: 14px;
    margin: 0;
    padding: 4px 8px;
    height: 30px;
    border: 1px solid transparent;
    border-bottom: 1px solid var(--gray);
}

.InputBox .Input.WithButton {
    padding: 4px 30px 0px 12px;
}

.InputBox .Input:hover {
    border-bottom: 1px solid var(--primary);
}

.InputBox .Input:focus,
.InputBox .Input:active {
    border: 1px solid var(--primary);
}

.InputBox.Error .Input {
    border: 1px solid var(--red);
}