.Text3D {
	font-size: inherit;
	color: var(--primary);
	letter-spacing: 2px;
	font-weight: 600;
	text-shadow:
		0px 0px 1px var(--primary-dark),
		0px 1px 1px var(--primary-dark),

		1px 1px 1px var(--primary-dark),
		1px 2px 1px var(--primary-dark);
}

.T12 {
	font-size: 12px !important;
}

.T14 {
	font-size: 14px !important;
}

.T16 {
	font-size: 16px !important;
}

.T18 {
	font-size: 18px !important;
}

.T20 {
	font-size: 20px !important;
}

.T22 {
	font-size: 22px !important;
}

.T24 {
	font-size: 24px !important;
}

.T26 {
	font-size: 26px !important;
}

.T32 {
	font-size: 32px !important;
}

.T34 {
	font-size: 34px !important;
}