.HR {
	float: left;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: row nowrap;
	width: calc(100% - 10px);
	height: 50px;
	padding: 0;
	text-align: left;
	margin: 0 5px;
	overflow: hidden;
}

.HR .HRStart,
.HR .HREnd {
	width: 50px;
	height: 0;
	max-height: 0;
	margin: 0;
	padding: 0;
	border: 0;
	opacity: 0.6;
}

.HR .HRSegment {
	font-size: 14px;
	white-space: nowrap;
	color: var(--gray-dark);
	margin: 0 5px;
}

.HR .HREnd {
	width: 100%;
}