@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;800&display=swap');

html {
  box-sizing: border-box;
  letter-spacing: 1.2px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  scroll-behavior: smooth;
  user-select: none;
  -webkit-user-select: none;
  -webkit-app-region: no-drag;
}

*:focus {
  outline: 0
}

*::-webkit-scrollbar {
  width: 10px;
  overflow: hidden;
  background: #fff;
  border: 0;
  border-left: 2px solid var(--primary);
  border-right: 2px solid var(--primary);
}

*::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: var(--primary);
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}