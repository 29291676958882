.Ribbon {
    float: left;
    color: #fff;
    background-color: var(--primary);
    height: 40px;
    font-size: 18px;
    text-align: left;
    margin: 0;
    padding: 6px 8px;
    box-shadow: var(--box-shadow);
    transform: translate(-10px);
    border: 0;
}


.Ribbon:before {
    content: "";
    position: absolute;
    top: 40px;
    left: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid var(--primary-dark);
    border-bottom: 5px solid transparent;
    border-top: 5px solid var(--primary-dark);
}

.Ribbon.Fixed {
    position: absolute;
    left: 0;
    top: 10px;
}