.Mosaic {
    /* Prevent vertical gaps */
    line-height: 0;

    -webkit-column-count: 5;
    -webkit-column-gap: 10px;
    -moz-column-count: 5;
    -moz-column-gap: 10px;
    column-count: 5;
    column-gap: 10px;
}

.Mosaic img {
    /* Just in case there are inline attributes */
    width: 100% !important;
    height: auto !important;
    margin: 5px 0;
    box-shadow: var(--box-shadow);
}

@media (max-width: 1200px) {
    .Mosaic {
        -moz-column-count: 4;
        -webkit-column-count: 4;
        column-count: 4;
    }
}

@media (max-width: 900px) {
    .Mosaic {
        -moz-column-count: 3;
        -webkit-column-count: 3;
        column-count: 3;
    }
}

@media (max-width: 768px) {
    .Mosaic {
        -moz-column-count: 2;
        -webkit-column-count: 2;
        column-count: 2;
    }
}

@media (max-width: 400px) {
    .Mosaic {
        -moz-column-count: 1;
        -webkit-column-count: 1;
        column-count: 1;
    }
}