.PromptBox {
	float: left;
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	max-height: 90%;
	max-width: 400px;
	width: 100%;
	overflow: hidden;
	border-radius: 4px;
	box-shadow: var(--box-shadow);
	z-index: 250;
}

.PromptBox .Header {
	float: left;
	height: 40px;
	width: 100%;
	overflow: hidden;
	color: #fff;
	font-size: 14px;
	padding: 12px;
	text-align: left;
}

.PromptBox .Body {
	float: left;
	height: 100px;
	width: 100%;
	margin: 0;
	padding: 12px;
	overflow: hidden;
	text-align: left;
	background: var(--white);
	transition: ease 1s;
	animation: unfold ease 1s;
}

@keyframes unfold {
	from {
		max-height: 0;
	}

	to {
		max-height: 999px;
	}
}

.PromptBox .Footer {
	float: left;
	min-height: 40px;
	width: 100%;
	overflow: hidden;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-end;
}

.PromptBox .Footer button {
	float: left;
	font-size: 14px;
	padding: 4px 8px;
	margin: 6px 12px;
	background-color: #fff;
	border: 2px solid transparent;
	border-radius: 4px;
}

.PromptBox .Footer button:hover,
.PromptBox .Footer button:focus {
	box-shadow: var(--box-shadow);
}

.PromptBox .Footer button:active {
	background-color: transparent;
	color: #fff;
	border: 2px solid #fff;
	box-shadow: var(--inset-shadow);
}

.PromptBox.Message .Header,
.PromptBox.Message .Footer {
	background: var(--primary);
}

.PromptBox.Error .Header,
.PromptBox.Error .Footer {
	background: var(--red);
}

.PromptBox.Warning .Header,
.PromptBox.Warning .Footer {
	background: var(--yellow);
}