.Layout {
    position: relative;
    float: left;
    top: 0;
    left: 0;
    border: 0;
    width: 100vw;
    overflow: hidden;
}

.Body {
    position: relative;
    float: left;
    top: 0;
    left: 0;
    border: 0;
    width: 100vw;
    min-height: 400px;
}

.WithBackground {
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
}

.Banner {
    float: left;
    position: relative;
    width: 100%;
    height: 100vh;
    padding: 20px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    align-items: flex-start;
}

.Header {
    position: relative;
    color: #fff;
    text-align: left;
    text-shadow: var(--text-shadow);
}

.Header .HomeLogo {
    position: absolute;
    max-width: 512px;
    width: 100%;
    bottom: -130px;
    right: 0;
}

.Header .HomeTagLine {
    position: absolute;
    bottom: 50px;
    right: 0;
    max-width: 512px;
    width: 100%;
    text-align: right;
    font-size: 32px;
    color: var(--primary);
    font-family: 'Dancing Script', cursive;
}

.Special {
    font-size: inherit;
    color: #fff;
    font-family: 'Pacifico', cursive;
}

.Services {
    padding: 20px 0;
}

.ServiceColumn {
    padding: 0 20px;
}

.ServiceHeading {
    font-size: 22px;
    color: var(--primary)
}

.ServiceImage {
    float: left;
    /* max-width: 250px; */
    width: 100%;
    height: 200px;
    margin: 10px 0;
    /* background-image: url('/static/media/images14.14de442f.jpg'); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.ServiceContent {
    text-align: justify;
}

.ContactUs {
    min-height: 300px;
    background-repeat: repeat;
    padding: 0 10px;
}

.ContactUs .Contact,
.ContactUs .GoogleMap,
.ContactUs .ServiceList,
.ContactUs .ContactForm {
    text-align: left;
    padding: 10px;
    overflow: hidden;
}

.ContactUs .Contact,
.ContactUs .ServiceList {
    color: #fff;
    text-shadow: var(--text-shadow);
}

.ContactUs .Contact ul {
    list-style: inside;
}

.ContactUs .Contact li {
    padding: 10px 0;
}

.ContactUs .ServiceList ul {
    list-style: inside;
}

.ContactUs .ServiceList li {
    padding: 10px 0;
}

.Content {
    float: left;
    background-color: #fff;
    border-radius: 4px;
    min-height: 275px;
    max-height: 275px;
    width: 100%;
    box-shadow: var(--box-shadow);
}