.InputBox {
    position: relative;
    float: left;
    display: block;
    margin: 4px 5px;
    padding: 8px 5px 0 5px;
    width: calc(100% - 10px);
}

.ToggleLabel {
    display: block;
    float: left;
    width: 100%;
    font-size: 0.8rem;
    margin: 0;
    padding: 0;
    text-align: left;
}

.Required {
    color: var(--red);
    font-size: inherit;
}

.ToggleLabel {
    width: calc(100% - 56px);
    max-width: calc(100% - 56px);
}

.Toggle {
    position: relative;
    float: left;
    display: block;
    width: 56px;
    max-width: 56px;
    height: 30px;
    padding: 0 4px;
    margin: 0;
}

.Toggle input {
    opacity: 0;
    width: 0;
    height: 0;
}

.Toggle .Slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 4px;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.Toggle .Slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 4px;
    bottom: 4px;
    border-radius: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.Toggle input:checked+.Slider {
    background-color: var(--primary);
}

.Toggle input:focus+.Slider {
    box-shadow: 0 0 1px var(--primary);
}

.Toggle input:checked+.Slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.Toggle2 {
    position: relative;
    display: inline-block;
    width: 56px;
    max-width: 56px;
    height: 5px;
    padding: 0 4px;
    margin: 12px 8px;
}

.Toggle2 input {
    opacity: 0;
    width: 0;
    height: 0;
}

.Toggle2 .Slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 5px;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.Toggle2 .Slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 0;
    bottom: -7px;
    box-shadow: var(--box-shadow);
    border-radius: 50%;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.Toggle2 input:checked+.Slider {
    background-color: var(--secondary-color);
}

.Toggle2 input:focus+.Slider {
    box-shadow: 0 0 1px var(--primary-color);
}

.Toggle2 input:checked+.Slider:before {
    -webkit-transform: translateX(38px);
    -ms-transform: translateX(38px);
    transform: translateX(38px);
}