@font-face {
	font-family: 'Roboto';
	font-style: normal;
	src: local('Roboto'), url('./Fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}

:root {
	--blue: #3366ff;
	--indigo: #5b47fb;
	--purple: #6f42c1;
	--pink: #f10075;
	--red: #dc3545;
	--orange: #fd7e14;
	--yellow: #ffc107;
	--green: #3bb001;
	--teal: #00cccc;
	--cyan: #17a2b8;
	--white: #fff;
	--gray: #6c757d;
	--gray-light: #bcbcbd;
	--gray-dark: #343a40;
	--primary: #c3185d;
	--primary-dark: #770030;
	--accent: #013e84;
	--primary-panel: linear-gradient(170deg, #007dc3 0%, #000000);
	--secondary: #f6993f;
	--secondary-dark: #ff8000;
	--secondary-panel: #f6993fbf;
	--success: #38c172;
	--info: #6cb2eb;
	--warning: #ffc107;
	--danger: #e3342f;
	--light: #f8f9fa;
	--dark: #343a40;
	--text-shadow: 2px 2px 4px #000000;
	--box-shadow: 0 4px 8px 0 rgba(12, 10, 10, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	--inset-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2) inset, 0 6px 20px 0 rgba(0, 0, 0, 0.19) inset;
	--breakpoint-xs: 0;
	--breakpoint-sm: 576px;
	--breakpoint-md: 768px;
	--breakpoint-lg: 992px;
	--breakpoint-xl: 1200px;
	--font-family: 'Roboto', sans-serif;
}